const TextField = ({ label, placeholder }) => {
  return (
    <div className="flex flex-col w-full h-full gap-2">
      <div className="font-clashdisplay text-[14px] text-light-green">
        {label}
      </div>
      <input
        className="h-[20px] rounded-full py-6 px-5 border-solid border-light-green border-[1px] text-black hover:border-[1px] hover:border-dark-green outline-light-green placeholder:text-black/10 bg-white"
        placeholder={placeholder}
        type="text"
        onChange={() => {}}
      />
    </div>
  );
};

export default TextField;

import { useRef } from "react";
import { useScroll, useTransform } from "framer-motion";

import SectionTitle from "../../components/Text/SectionTitle";
import circle from "../../assets/circle-footer.svg";
import footerIcon from "../../assets/footer-icon.svg";
import logo from "../../assets/logo-white.png";
import phone from "../../assets/phone.svg";
import email from "../../assets/email.svg";
import address from "../../assets/address.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";

const days = [
  {
    day: "Monday",
    hours: "Closed",
  },
  {
    day: "Tuesday",
    hours: "10am - 7pm",
  },
  {
    day: "Wednesday",
    hours: "10am - 7pm",
  },
  {
    day: "Thursday",
    hours: "10am - 7pm",
  },
  {
    day: "Friday",
    hours: "10am - 7pm",
  },
  {
    day: "Saturday",
    hours: "10am - 7pm",
  },
  {
    day: "Sunday",
    hours: "9am - 2pm",
  },
];

const Footer = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 1800]);
  return (
    <div
      id="footer"
      ref={targetRef}
      className="relative flex flex-col pt-20 z-10 h-[90vh] w-[100vw] bg-black pb-40"
    >
      <SectionTitle icon={circle} rotate={rotate} text="text-white">
        Office hours
      </SectionTitle>
      <div className="flex flex-col gap-10 w-5/6 mx-auto py-16">
        <div className="flex flex-1 justify-between">
          <img alt="footer" src={footerIcon} className="w-[32px] h-[32px]" />
          {days.map((x) => (
            <div className="flex flex-col">
              <div className="font-clashdisplay text-[16px] text-light-green">
                {x.day}
              </div>
              <div className="font-poppins text-[20px] text-[#fff] font-bold">
                {x.hours}
              </div>
            </div>
          ))}
        </div>
        <div className="flex mt-16 h-[160px] justify-between">
          <div className="flex flex-col w-1/3 justify-between">
            <img className="w-[132px]" alt="logo" src={logo} />
            <div className="font-poppins text-light-green text-[14px]">
              © 2023 by Dentio Dental.
            </div>
          </div>
          <div className="flex flex-col w-1/3 justify-between">
            <div className="font-clashdisplay text-light-green text-[24px]">
              Contact Us
            </div>
            <div className="flex items-center">
              <img className="w-[24px] mr-4" alt="phone" src={phone} />
              <div className="font-poppins text-white text-[16px]">
                604-227-2232
              </div>
            </div>
            <div className="flex items-center">
              <img className="w-[24px] mr-4" alt="phone" src={email} />
              <div className="font-poppins text-white text-[16px]">
                hello@dentio.ca
              </div>
            </div>
            <div className="flex items-center">
              <img className="w-[24px] mr-4" alt="phone" src={address} />
              <div className="font-poppins text-white text-[16px]">
                Unit E2, 10118 - 152th Street, Surrey, BC, V3R 9W3
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/3 pl-20">
            <div className="font-clashdisplay text-light-green text-[24px]">
              Follow Us
            </div>
            <div className="flex gap-4 mt-5">
              <div className="h-[70px] w-[70px] rounded-full bg-light-green flex justify-center items-center">
                <img alt="facebook" src={facebook} />
              </div>
              <div className="h-[70px] w-[70px] rounded-full bg-light-green flex justify-center items-center">
                <img alt="instagram" src={instagram} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import { motion, AnimatePresence } from "framer-motion";
import PageTransition from "../components/PageTransition";

import Hero from "./Hero";

function Services() {
  return (
    <motion.div className="bg-black relative">
      <PageTransition />
      <Hero />
    </motion.div>
  );
}

export default Services;

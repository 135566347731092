import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import { FiX as Cancel } from "react-icons/fi";
import SquareButton from "../Button/SquareButton";
import HoverButton from "../Button/HoverButton";
import logo from "../../assets/logo.png";

const Navbar = () => {
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const navigate = useNavigate();
  return (
    <nav className="bg-navbar z-50 w-full fixed top-0 py-5 px-10">
      <div className="flex items-center justify-between mx-auto w-full">
        {/* DESKTOP NAV */}
        {isDesktop ? (
          <div className="flex w-2/6 gap-5 font-clashdisplay text-[16px] text-black">
            <div
              className="text-black hover:text-dark-green cursor-pointer"
              onClick={() => navigate("/")}
            >
              Home
            </div>
            <div
              className="text-black hover:text-dark-green cursor-pointer"
              onClick={() => navigate("/services")}
            >
              Services
            </div>
            <div
              className="text-black hover:text-dark-green cursor-pointer"
              onClick={() => navigate("/about")}
            >
              About
            </div>
          </div>
        ) : (
          <button
            className="rounded-full bg-light-green p-2"
            onClick={() => setIsMenuToggled(!isMenuToggled)}
          >
            <img alt="menu-icon" src="../assets/menu-icon.svg" />
          </button>
        )}
        <div
          className="flex w-2/6 justify-center cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img className="h-[42px] w-[112px]" alt="logo" src={logo} />
        </div>

        <div className="flex w-2/6 justify-end">
          <SquareButton classes="text-[16px]" label="Book now" />
          <HoverButton classes="text-[16px]" label="Book now" />
        </div>

        {/* MOBILE MENU POPUP */}
        {!isDesktop && isMenuToggled && (
          <div className="fixed right-0 bottom-0 h-full bg-deep-blue w-[100%]">
            {/* CLOSE ICON */}
            <div className="flex justify-end p-12">
              <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                <Cancel className="text-black" size={32} />
              </button>
            </div>

            {/* MENU ITEMS */}
            <div className="flex flex-col items-center gap-10 text-2xl text-black">
              <div
                className="text-black hover:text-dark-green cursor-pointer"
                onClick={() => navigate("/")}
              >
                Home
              </div>
              <div
                className="text-black hover:text-dark-green cursor-pointer"
                onClick={() => navigate("/services")}
              >
                Services
              </div>
              <div
                className="text-black hover:text-dark-green cursor-pointer"
                onClick={() => navigate("/about")}
              >
                About
              </div>
              {/* <Link
                page="Skills"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              <Link
                page="Projects"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              
              <Link
                page="Contact"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              /> */}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

import { useRef, useEffect, useState } from "react";
import { useScroll, useTransform } from "framer-motion";
import classNames from "classnames";
import BorderButton from "../../components/Button/BorderButton";
import { motion } from "framer-motion";

import SectionTitle from "../../components/Text/SectionTitle";

import circleOurTeam from "../../assets/circle-our-team.svg";
import khanti from "../../assets/kt-profile.png";
import leo from "../../assets/leo-profile.png";
import unknown from "../../assets/unknown-profile.png";
import doctorIcon from "../../assets/doctor.svg";

const persons = [
  {
    name: "Dr. Kantidara Tjiang",
    description:
      "I've gone through anxiety at the dental office at a young age. I'm now in a position to take that and turn it into positive experiences for my own patients.",
    languages: ["Fluent English", "Basic Indonesian", "Basic Mandarin"],
    img: khanti,
    icon: doctorIcon,
  },
  {
    name: "Dr. Kantidara Tjiang",
    description:
      "I've gone through anxiety at the dental office at a young age. I'm now in a position to take that and turn it into positive experiences for my own patients.",
    languages: ["Fluent English", "Basic Indonesian", "Basic Mandarin"],
    img: khanti,
    icon: doctorIcon,
  },
  {
    name: "Dr. Leo Wang",
    description:
      "I've come to realize how scary a dental visit could be for most patients. But I always tell them it doesn't have to be that way if we do the little things right.",
    languages: ["Fluent English", "Fluent Mandarin", "Basic Taiwanese"],
    img: leo,
    icon: doctorIcon,
  },
  {
    name: "Incoming",
    description: "Bio incoming.",
    languages: ["Fluent English", "Fluent Korean"],
    img: unknown,
    icon: doctorIcon,
  },
  {
    name: "Incoming",
    description: "Bio incoming.",
    languages: ["Fluent English", "Fluent Korean"],
    img: unknown,
    icon: doctorIcon,
  },
  {
    name: "Incoming",
    description: "Bio incoming.",
    languages: ["Fluent English", "Fluent Korean"],
    img: unknown,
    icon: doctorIcon,
  },
];
const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const contentVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};
const OurTeam = () => {
  const [activeItem, setActiveItem] = useState(1);
  const wrapperRef = useRef(null);
  const timeoutRef = useRef(null);

  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 1800]);

  useEffect(() => {
    if (!wrapperRef.current) return;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    wrapperRef.current.style.setProperty(
      "--transition",
      "600ms cubic-bezier(0.22, 0.61, 0.36, 1)"
    );

    timeoutRef.current = setTimeout(() => {
      wrapperRef.current?.style.removeProperty("--transition");
    }, 900);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [activeItem]);
  return (
    <div
      id="services"
      ref={targetRef}
      className="relative flex flex-col pt-20 z-10 w-[100vw] bg-white pb-40"
    >
      <SectionTitle icon={circleOurTeam} rotate={rotate}>
        Meet the team
      </SectionTitle>
      <motion.div
        variants={contentVariant}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 0.5, type: "spring" }}
        className="max-w-4/6 px-32"
      >
        <ul
          ref={wrapperRef}
          className="group flex flex-col gap-3 md:h-[460px] md:flex-row md:gap-[1.5%]"
        >
          {persons.map((person, index) => (
            <li
              onClick={() => setActiveItem(index)}
              aria-current={activeItem === index}
              className={classNames(
                "relative cursor-pointer md:w-[8%] md:first:w-[1%] md:last:w-[1%] md:[&[aria-current='true']]:w-[80%]",
                "md:[transition:width_var(--transition,500ms_ease-in)]",
                "md:before-block before:absolute before:bottom-0 before:left-[-10px] before:right-[-10px] before:top-0 before:hidden before:bg-[#ffffff]",
                "md:[&:not(:hover),&:not(:first),&:not(:last)]:group-hover:w-[7%] md:hover:w-[12%]",
                "first:pointer-events-none last:pointer-events-none md:[&_img]:first:opacity-0 md:[&_img]:last:opacity-0"
              )}
              key={person.name}
            >
              <div className="relative h-full max-w-full overflow-hidden rounded-2xl bg-[#ffffff]">
                <div className="flex items-center h-full w-[435px] p-4">
                  <div className="relative">
                    <img
                      className={classNames(
                        "w-24 max-w-none md:w-[270px] rounded-2xl block margin-transition",
                        activeItem === index ? "" : "grayscale",
                        activeItem === index ? "" : "ml-[-85px]"
                      )}
                      src={person.img}
                      alt={person.name}
                    />
                    {activeItem === index ? (
                      <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        variants={variants}
                        transition={{ duration: 0.5, delay: 1 }}
                        className={classNames(
                          "absolute m-auto inset-0	border-dark-green h-[calc(100%-20px)] w-[calc(100%-20px)] rounded-2xl border-solid border-[1px]"
                        )}
                      />
                    ) : null}
                    <motion.div
                      className={classNames(
                        "absolute flex justify-center items-center right-[-10px] bottom-[-10px] w-[80px] h-[80px] rounded-full bg-dark-green transition-opacity",
                        activeItem === index ? "opacity-100" : "opacity-0"
                      )}
                    >
                      <img
                        className="w-[32px] h-[32px]"
                        alt="icon"
                        src={person.icon}
                      />
                    </motion.div>
                  </div>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    transition={{ duration: 0.5, delay: 0.3 }}
                    variants={{
                      hidden: { opacity: 0 },
                      visible: { opacity: 1 },
                    }}
                    className="h-full"
                  >
                    <div
                      className={classNames(
                        "inset-0 opacity-25 before:absolute before:bottom-0 before:left-[-546px] before:right-0 before:top-[-148px] before:z-10 before:bg-texture  after:bottom-[28px] after:left-0 after:right-[-434px] after:top-0 after:z-10 after:bg-texture md:absolute md:transition-opacity",
                        activeItem === index ? "md:opacity-25" : "md:opacity-0"
                      )}
                    />
                    <div
                      className={classNames(
                        "flex flex-col h-full gap-5 w-[435px] pt-24 pl-16 pr-8 pb-20",
                        activeItem === index ? "opacity-100" : "opacity-0"
                      )}
                    >
                      <span className="font-clashdisplay text-black md:text-[24px] font-bold">
                        {person.name}
                      </span>
                      <p
                        className={classNames(
                          "font-poppinsBold text-black md:text-[16px]"
                        )}
                      >
                        {person.description}
                      </p>

                      <div className="flex gap-3 flex-wrap ">
                        {person.languages.map((x) => (
                          <div className="flex justify-center max-h-[37px] items-center p-3 rounded-full bg-light-green hover:bg-dark-green transition-colors duration-700">
                            <p className="text-clashdisplay text-[10px]">{x}</p>
                          </div>
                        ))}
                      </div>
                      <div className="flex-1 flex items-end">
                        <BorderButton
                          classes="w-[120px] h-[40px] border-dark-green text-[14px] bg-white text-dark-green"
                          label="Read more"
                        />
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </motion.div>
    </div>
  );
};

export default OurTeam;

import backgroundVideo from "../../assets/contact.mp4";

const ContactUs = () => {
  return (
    <div
      id="services"
      className="relative flex justify-center items-center pt-20 z-10 w-[100vw] h-[400px] bg-white"
    >
      <div class="z-[-10] video-docker absolute top-0 left-0 w-full h-full overflow-hidden">
        <video
          class="min-w-full min-h-full absolute object-cover"
          src={backgroundVideo}
          type="video/mp4"
          autoplay="{true}"
          loop
          muted
        ></video>
        <div className="absolute min-w-full min-h-full contact-video" />
      </div>
      <div className="w-5/6 self-center text-[94px] font-clashdisplay text-dark-green  leading-[74px]">
        Your peace of mind{" "}
        <span className="text-light-green">is just as important</span> as the
        health of your teeth.
      </div>
    </div>
  );
};

export default ContactUs;

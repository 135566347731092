import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Landing from "./Landing";
import Services from "./Services";
import About from "./About/About";
import { AnimatePresence } from "framer-motion";

function routes() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();

  return (
    <AnimatePresence
      mode="wait"
      onExitComplete={() => {
        if (typeof window !== "undefined") {
          window.scrollTo({ top: 0, behavior: "instant" });
        }
      }}
    >
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/about" element={<About />} />
      </Routes>
    </AnimatePresence>
  );
}

export default routes;

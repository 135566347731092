import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";

import useMediaQuery from "../../hooks/useMediaQuery";
import { tw } from "../../utils";

const Testimonial = ({ testimonial, i }) => {
  const { name, rating, time, text } = testimonial;
  const isMobile = useMediaQuery("(max-width: 480px)");

  const generateRatings = () => {
    const stars = [];
    for (let i = rating; i > 0; i -= 1) {
      if (i < 1) {
        const decimal = i.toString().split(".")[1];
        if (Number(decimal) <= 5) {
          stars.push(
            <BsStarHalf
              key={uuidv4()}
              className="text-light-green"
              size={isMobile ? 16 : 16}
            />
          );
        }
      } else {
        stars.push(
          <BsStarFill
            key={uuidv4()}
            className="text-light-green"
            size={isMobile ? 16 : 16}
          />
        );
      }
    }
    for (let i = stars.length; i < 5; i += 1) {
      stars.push(
        <BsStar
          key={uuidv4()}
          className="text-light-green"
          size={isMobile ? 16 : 16}
        />
      );
    }

    return stars;
  };
  return (
    <motion.div
      className={tw(
        "relative flex flex-col",
        "rounded-xl p-10",
        "bg-dark-green max-w-[250px] md:max-w-[350px]"
      )}
      // initial="hidden"
      // whileInView="visible"
      // viewport={{ once: true }}
      // transition={{ duration: 0.6 }}
      // whileHover={{
      //   scale: 1.02,
      // }}
      // variants={{
      //   hidden: { opacity: 0, scale: 0.8 },
      //   visible: { opacity: 1, scale: 1 },
      // }}
    >
      <div className="flex gap-6">
        <div className="flex flex-col gap-1">
          <span className="font-clashdisplay md:text-xl text-md text-white font-bold">
            {name}
          </span>
          <span className="font-poppinsBold text-md text-light-green">
            {time}
          </span>
        </div>
      </div>
      <span className="pt-4 font-poppinsBold text-[16px] text-white flex-1">
        {text}
      </span>
      <div className="flex gap-1 mt-6">{generateRatings()}</div>
    </motion.div>
  );
};

export default Testimonial;

import { useState, useRef, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { v4 as uuidv4 } from "uuid";

import Testimonial from "./Testimonial";
import circle from "../../assets/circle-testimonials.svg";
import { useReviews } from "./utils";
import Google from "../../assets/google-logo.svg";
import backgroundVideo from "../../assets/testimonials-background.mp4";
import { tw } from "../../utils";

const variant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const containerVariant = {
  hidden: { opacity: 0, y: 50 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.5,
      type: "spring",
    },
  },
};

const Testimonials = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 1800]);
  const [leftHeight, setLeftHeight] = useState(0);
  const leftCarousel = useRef(0);
  const [rightHeight, setRightHeight] = useState(0);
  const rightCarousel = useRef(0);
  useEffect(() => {
    setLeftHeight(
      leftCarousel.current.scrollHeight - leftCarousel.current.offsetHeight + 50
    );
  }, []);

  useEffect(() => {
    setRightHeight(
      rightCarousel.current.scrollHeight -
        rightCarousel.current.offsetHeight +
        50
    );
  }, []);
  const [leftComments, rightComments] = useReviews();
  return (
    <div
      id="testimonials"
      className="relative h-[120vh] w-[100%] overflow-hidden pb-20"
    >
      {/* HEADING */}
      {/* <motion.div
        className="md:w-1/3 text-center md:text-left"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
      
      </motion.div> */}
      <div class="z-[-10] video-docker absolute top-0 left-0 w-full h-full overflow-hidden">
        <video
          class="min-w-full min-h-full absolute object-cover brightness-[0.2]"
          src={backgroundVideo}
          type="video/mp4"
          autoplay="{true}"
          loop
          muted
        ></video>
      </div>
      <motion.div className="px-20 pb-16 flex h-[120vh]">
        <motion.div
          variants={variant}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.5, type: "spring" }}
          className="flex-1 flex flex-col px-20 mt-32 items-start md:text-left"
        >
          <motion.img
            style={{ rotate }}
            className="w-[65px] h-[65] cursor-grab mb-10"
            src={circle}
            drag
            dragElastic={0.18}
            dragConstraints={{ top: 0, bottom: 0, right: 0, left: 0 }}
            whileTap={{ cursor: "grabbing" }}
          />
          <span className="font-clashdisplay text-[94px] text-white leading-[74px]">
            What people love about us
          </span>
          <img alt="google" src={Google} className="w-[200px] h-[70px] mt-24" />
        </motion.div>
        {/* TESTIMONIALS */}
        <div className="flex gap-2">
          <motion.div
            ref={leftCarousel}
            className="rounded-xl cursor-grab max-h-[130vh] overflow-hidden w-[100%] h-[100%] mt-6"
          >
            <motion.div
              className={tw("flex flex-col self-center", "gap-2 max-w-[350px]")}
              drag="y"
              dragConstraints={{ top: -leftHeight, bottom: 0 }}
              variants={containerVariant}
              initial="hidden"
              whileInView="show"
            >
              {leftComments.map((x, i) => (
                <Testimonial key={uuidv4()} i={i} testimonial={x} />
              ))}
            </motion.div>
          </motion.div>
          <motion.div
            ref={rightCarousel}
            className="rounded-xl cursor-grab max-h-[130vh] overflow-hidden w-[100%] h-[100%] mt-6"
          >
            <motion.div
              className={tw("flex flex-col self-center", "gap-2 max-w-[350px]")}
              drag="y"
              dragConstraints={{ top: -rightHeight, bottom: 0 }}
              variants={containerVariant}
              initial="hidden"
              whileInView="show"
            >
              {rightComments.map((x) => (
                <Testimonial key={uuidv4()} testimonial={x} />
              ))}
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Testimonials;

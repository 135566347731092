import { useRef } from "react";

import BorderButton from "../../components/Button/BorderButton";
import { motion, useScroll, useTransform } from "framer-motion";
import background from "../../assets/hero-background.png";
import circleMore from "../../assets/circle-more.svg";
import moss from "../../assets/hero-moss-background.jpeg";

const h1Variant = {
  hidden: { opacity: 0, y: -250 },
  visible: { opacity: 1, y: 0 },
};
const h2Variant = {
  hidden: { opacity: 0, y: -100 },
  visible: { opacity: 1, y: 0 },
};
const h3Variant = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};
const contentVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};
const Hero = ({ setSelectedPage }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start start", "end end"],
  });
  const { scrollYProgress: spinningYProgress } = useScroll({
    target: targetRef,
    offset: ["start start", "end start"],
  });
  const width = useTransform(
    scrollYProgress,
    [0, 0.5, 1],
    ["50%", "90%", "100%"]
  );
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 1800]);
  const opacity = useTransform(spinningYProgress, [0, 1], [1, 0]);
  const position = useTransform(scrollYProgress, (pos) => {
    return pos === 1 ? "relative" : "sticky";
  });
  return (
    <motion.div
      id="home"
      ref={targetRef}
      className="h-[150vh] m-auto w-full md:flex md:justify-between relative bg-black"
    >
      <div className="absolute left-0 top-0 z-30"></div>
      <motion.div
        style={{ width, position, "--image-url": `url(${moss})` }}
        className="min-h-[100vh] flex flex-col pl-32 bg-dark-green z-10 bg-cover bg-[image:var(--image-url)] before:absolute before:w-full before:h-full before:left-0 before:right-0 before:top-0 before:bottom-0  before:bg-gradient-to-b before:from-black/40 before:to-black/80"
      >
        <motion.div className="sticky flex flex-col top-[200px] gap-16 text-8xl font-clashdisplay font-normal pb-40">
          <div className="flex w-full gap-16 items-center">
            <motion.div
              variants={h1Variant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              whileHover={{
                scale: 1.01,
                transition: { duration: 1 },
              }}
              transition={{ duration: 1, delay: 0.2, type: "spring" }}
              className="flex flex-col"
            >
              <div className="h-[77px] underline decoration-1 decoration-light-green underline-offset-8">
                Relax
              </div>
              <div className="h-[77px]">your</div>
              <div className="h-[77px]">mind</div>
            </motion.div>
            <motion.div
              variants={h1Variant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              whileHover={{
                scale: 1.01,
                transition: { duration: 1 },
              }}
              transition={{ duration: 1, delay: 0.2, type: "spring" }}
              className="flex flex-col"
            >
              <div className="h-[77px] underline decoration-1 decoration-light-green underline-offset-8">
                Refresh
              </div>
              <div className="h-[77px]">your</div>
              <div className="h-[77px]">smile</div>
            </motion.div>
          </div>
          <div className="flex w-full gap-9 items-center">
            <motion.div
              variants={h2Variant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.4, type: "spring" }}
            >
              <BorderButton
                classes="w-[271px] text-[20px] border-white "
                label="Book now"
              />
            </motion.div>
            <motion.div
              variants={h2Variant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.4, type: "spring" }}
              className="font-poppins text-[18px] min-w-[300px]"
            >
              or call: (604) 227-2232
            </motion.div>
          </div>
          <div className="flex w-full gap-16 items-center pt-6">
            <motion.div
              variants={h3Variant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.6, type: "spring" }}
              className="relative flex items-center justify-center min-w-[70px]"
            >
              <div className="absolute h-2 w-2 bg-[#464d3e] rounded-full" />
              <div className="absolute opacity-50 h-6 w-6 border-[0.1px] border-solid border-[#464d3e] rounded-full" />
              <motion.img
                style={{ rotate }}
                alt="circle-more"
                src={circleMore}
              />
            </motion.div>
            <motion.div
              variants={h3Variant}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.6, type: "spring" }}
              className="font-poppins text-[18px] min-w-[400px] max-w-[400px]"
            >
              Come meet your new dentist & experience a stress-free
              transformation.
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        style={{ opacity }}
        variants={contentVariant}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 0.2, type: "spring" }}
        className="fixed min-w-[50%] h-full flex right-0 top-0"
      >
        <div
          style={{
            "--image-url": `url(${background})`,
          }}
          className="w-full h-[100vh] bg-black bg-cover bg-center bg-[image:var(--image-url)]"
        />
      </motion.div>
      {/* <div className="z-10 basis-3/5 mt-12 md:mt-32">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="md:text-6xl text-4xl font-clashdisplay z-10 text-center md:text-start">
            Relax Your
            <span className="relative text-light-green"> Mind</span>
            <br />
            Refresh Your
            <span className="relative text-light-green"> Smile</span>
          </p>

          <p className="mt-10 mb-7 text-sm text-center md:text-start font-clashdisplay">
            Come meet your new dentist & experience a stress-free
            transformation.
          </p>
        </motion.div>

        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <AnchorLink
            className="bg-gradient-rainblue text-deep-blue rounded-sm py-3 px-7 font-semibold
              hover:bg-blue hover:text-white transition duration-500"
            // onClick={() => setSelectedPage("contact")}
            // href="#contact"
          >
            Book Now
          </AnchorLink>
        </motion.div>

        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <SocialMediaIcons />
        </motion.div>
      </div> */}
    </motion.div>
  );
};

export default Hero;

import { useRef, useState, Fragment } from "react";
import { useScroll, useTransform } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import {
  BsFillArrowLeftCircleFill as Left,
  BsFillArrowRightCircleFill as Right,
} from "react-icons/bs";
import { motion, useInView } from "framer-motion";
import "./OurServices.css";
import useMediaQuery from "../../hooks/useMediaQuery";
import circleService from "../../assets/circle-services.svg";
import SectionTitle from "../../components/Text/SectionTitle";

import service1 from "../../assets/service-1.png";
import service1Icon from "../../assets/service-1-icon.svg";
import service2 from "../../assets/service-2.png";
import service2Icon from "../../assets/service-2-icon.svg";
import service3 from "../../assets/service-3.png";
import service3Icon from "../../assets/service-3-icon.svg";
import service4 from "../../assets/service-4.png";
import service4Icon from "../../assets/service-4-icon.svg";
import classNames from "classnames";

const services = [
  {
    image: service1,
    icon: service1Icon,
    title: "Exam checkup",
    description:
      "Let's check your teeth to make sure everything is in top shape.",
  },
  {
    image: service2,
    icon: service2Icon,
    title: "Cleaning",
    description:
      "Get squeaky clean teeth for a special event, date night, or just because.",
  },
  {
    image: service3,
    icon: service3Icon,
    title: "Restorative",
    description:
      "Remedy those cavities, chips or cracks in just a quick visit.",
  },
  {
    image: service4,
    icon: service4Icon,
    title: "Cosmetic",
    description: "Look into options for creating your desired smile.",
  },
];

function Service({ service, position, index }) {
  const ref = useRef(null);
  const inView = useInView(ref, { margin: "0px -50% 0px -50%" });
  const isMobile = useMediaQuery("(max-width: 480px)");
  const distance = isMobile ? 28 : 28;
  return (
    <motion.div
      initial={{ scale: 0, rotation: -180 }}
      animate={{
        rotate: 0,
        left: `${(index - position) * 50 - distance}vw`,
        scale: index === position ? 1 : 0.8,
      }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
        scale: { duration: 0.5 },
      }}
      className={classNames(
        "card overflow-hidden",
        position === index ? "" : "blur-sm"
      )}
    >
      <div
        className={classNames(
          "card__content",
          position !== index ? "" : "card__hover"
        )}
      >
        <div
          ref={ref}
          style={{ "--image-url": `url(${service.image})` }}
          className="card__front image bg-cover bg-center bg-[image:var(--image-url)]"
        >
          <h3 className="card__title font-clashdisplay text-[100px] text-dark-green">
            {service.title}
          </h3>
        </div>

        <div className="card__back">
          <p className="card__body text-light-green font-poppins text-[18px]">
            {service.description}
          </p>
        </div>
      </div>
    </motion.div>
  );
}

const buttons = {
  whileHover: {
    scale: 1.2,
    transition: { duration: 0.4 },
  },
  whileTap: { scale: 0.9 },
};

function Slider() {
  const [position, positionSet] = useState(0);

  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 1800]);

  const onRight = () => {
    if (position < services.length - 1) {
      positionSet(position + 1);
    }
  };
  const onLeft = () => {
    if (position > 0) {
      positionSet(position - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => onRight(),
    onSwipedRight: () => onLeft(),
    swipeDuration: 1000,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });
  return (
    <div className="relative pt-20 items-center z-10 w-[100vw] h-[100vh] bg-white">
      <SectionTitle icon={circleService} rotate={rotate}>
        Meet the team
      </SectionTitle>
      <div className="App">
        <div className="row" {...handlers}>
          {services.map((service, index) => (
            <Fragment key={index}>
              <Service index={index} service={service} position={position} />
            </Fragment>
          ))}
        </div>
      </div>

      <motion.div
        whileHover="whileHover"
        whileTap="whileTab"
        variants={buttons}
        className="top-[57%] opacity-0 absolute my-auto left-[2vw] cursor-pointer sm:opacity-100"
      >
        <Left
          className="text-black shadow rounded-full"
          onClick={onLeft}
          size={60}
        />
      </motion.div>
      <motion.div
        whileHover="whileHover"
        whileTap="whileTab"
        variants={buttons}
        className="top-[57%] opacity-0 absolute my-auto right-[2vw] cursor-pointer sm:opacity-100"
      >
        <Right
          className="text-black shadow rounded-full"
          onClick={onRight}
          size={60}
        />
      </motion.div>
    </div>
  );
}

export default Slider;

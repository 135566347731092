import { useRef } from "react";

import BorderButton from "../../components/Button/BorderButton";
import Button from "../../components/Button";
import SectionTitle from "../../components/Text/SectionTitle";
import { motion, useScroll, useTransform } from "framer-motion";
import circle from "../../assets/circle-services.svg";
import timer from "../../assets/timer.svg";
import classNames from "classnames";
const contentVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};
const Hero = ({ service }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 1800]);
  return (
    <div
      id="services"
      ref={targetRef}
      className={classNames(
        "relative flex flex-col pt-20 z-10 w-[100vw] pb-40",
        service.darkTheme ? "bg-black" : "bg-white "
      )}
    >
      <SectionTitle icon={circle} rotate={rotate}>
        {service.page}
      </SectionTitle>
      <div className="flex flex-col gap-10 w-5/6 m-auto justify-between">
        <div className="font-poppinsLight text-black text-[18px]">
          {service.description}
        </div>
        {service.services.map((x) => (
          <motion.div
            variants={contentVariant}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.2, type: "spring" }}
            className={classNames(
              "w-full min-h-[500px] rounded-[153px] flex gap-20 p-3",
              service.darkTheme
                ? "bg-black border-[1px] border-solid border-white/50"
                : "bg-[#fff]"
            )}
          >
            <div className="relative h-full w-[385px] rounded-[153px]">
              <img
                className="min-h-[500px] h-full w-[385px] object-cover rounded-[153px]"
                alt={x.title}
                src={x.image}
              />
              <div
                className={classNames(
                  "absolute m-auto inset-0 border-dark-green/10 h-[calc(100%-20px)] w-[calc(100%-20px)] rounded-[153px] border-solid border-[1px]"
                )}
              />
            </div>
            <div className="flex-1 flex flex-col py-[90px] pr-[100px]">
              <div className="flex gap-3 items-center">
                <div
                  className={classNames(
                    "font-clashdisplay  text-[24px] font-bold",
                    service.darkTheme ? "text-white" : "text-black"
                  )}
                >
                  {x.title}
                </div>
                {!!x.titleButton && (
                  <BorderButton
                    classes="border-light-green"
                    label={x.titleButton}
                    padding="p-2"
                    disableHover
                    text="text-light-green"
                  />
                )}
              </div>
              <div className="mt-5 flex flex-wrap gap-2">
                {x.descriptionButton
                  ? x.descriptionButton?.map((y) => (
                      <BorderButton
                        classes="border-light-green"
                        label={y}
                        padding="p-2"
                        disableHover
                        text="text-light-green"
                      />
                    ))
                  : null}
              </div>
              <div
                className={classNames(
                  "font-poppinsLight leading-6 text-[16px] italic mt-6",
                  service.darkTheme ? "text-white" : "text-black"
                )}
              >
                {x.descriptionItalic}
              </div>
              <div
                className={classNames(
                  "font-poppinsLight leading-6 text-[16px] mt-6",
                  service.darkTheme ? "text-white" : "text-black"
                )}
              >
                {x.description}
              </div>
              <div className="mt-10 flex gap-3 items-center">
                <Button classes="bg-[#3F7652]" label="Book now" />
                <BorderButton
                  classes={service.darkTheme ? "border-white" : "border-black"}
                  label="Call to book"
                  text={service.darkTheme ? "text-white" : "text-black"}
                />
                <img
                  className="ml-10 w-[24px] h-[24px]"
                  src={timer}
                  alt="timer"
                />
                <div className="flex flex-col gap-5">
                  {x.duration?.map((y) => (
                    <div className="font-poppinsLight text-light-green text-[14px]">
                      {y}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Hero;

import Button from "../../components/Button";
import contactIcon from "../../assets/contact-icon.svg";

const GetInTouch = () => {
  return (
    <div
      id="services"
      className="relative flex flex-col pt-20 z-10 w-[100vw] bg-white pb-40"
    >
      <div className="flex justify-center items-center w-5/6 h-[225px] self-center rounded-full bg-[#ffffff] relative">
        <div className="m-auto absolute w-[calc(100%-20px)] h-[calc(100%-20px)] border-[1px] border-solid rounded-full color-light-green" />
        <div className="flex w-full h-full px-[100px] items-center gap-12">
          <img className="w-[32px] h-[32px]" alt="contact" src={contactIcon} />
          <div className="flex-1 flex flex-col gap-5">
            <div className="font-clashdisplay text-[24px] text-dark-green">
              Get in Touch
            </div>
            <div className="font-poppins text-[16px] text-black">
              We would love to hear from you! Share your thoughts, questions &
              comments and we will be sure to reach back to you as soon as
              possible.
            </div>
          </div>
          <div className="">
            <Button classes="text-[16px]" label="Book now" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;

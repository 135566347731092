export const reviews = [
  {
    author_name: "Luke Archibald",
    author_url:
      "https://www.google.com/maps/contrib/113389359827989670652/reviews",
    language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a-/AOh14GhGGmTmvtD34HiRgwHdXVJUTzVbxpsk5_JnNKM5MA=s128-c0x00000000-cc-rp-mo",
    rating: 4.5,
    relative_time_description: "a week ago",
    text: "Very nice Dental office. Netflix on demand. Friendly staff. The dentist is very thorough. Dentist and assistant have very light hands. Never thought I would say this but I cant wait for my next appointment!",
    time: 1652286798,
  },
  {
    author_name: "Tevita Taufoou",
    author_url:
      "https://www.google.com/maps/contrib/105937236918123663309/reviews",
    language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AATXAJwZANdRSSg96QeZG--6BazG5uv_BJMIvpZGqwSz=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "6 months ago",
    text: "I moved from downtown Vancouver and was looking for a new dentist office near by. I had my first appointment with Dr. Kantidara last week and it was one of the best dentist experiences I've ever had. Dr. Kantidara is very personable and knowledgeable. As a bonus, their office is beautiful and doesnt have the medical smell.",
    time: 1637215605,
  },
  {
    author_name: "Jordy Baker",
    author_url:
      "https://www.google.com/maps/contrib/102582237417399865640/reviews",
    language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AATXAJwgg1tM4aVA4nJCMjlfJtHtFZuxF475Vb6tT74S=s128-c0x00000000-cc-rp-mo",
    rating: 4,
    relative_time_description: "4 months ago",
    text: "The reception team is excellent! I got an appointment promptly with no waiting at the office when I arrived. The doctor was informative and very gentle! I will definitely make this my new dentist office! They are fabulous! And the price is right!! I’m so happy I found Dentio Dental!",
    time: 1641389490,
  },
  {
    author_name: "Prem Rathod",
    author_url:
      "https://www.google.com/maps/contrib/115981614018592114142/reviews",
    language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AATXAJyEQpqs4YvPPzMPG2dnnRTFPC4jxJfn8YXnm2gz=s128-c0x00000000-cc-rp-mo",
    rating: 3,
    relative_time_description: "4 months ago",
    text: "I had a great experience at Dentio. The staff and Dr.K we’re amazing. My partner recommended I come here and I was not disappointed. Dr.K help me feel safe and also made me laugh haha. She knew I get anxious visiting the dentist. I highly recommend Dentio.",
    time: 1640159655,
  },
  {
    author_name: "Husuni Hamza",
    author_url:
      "https://www.google.com/maps/contrib/102167316656574288776/reviews",
    language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AATXAJwRkyvoSlgd06ahkF9XI9D39o6Zc_Oycm5EKuRg=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "7 months ago",
    text: "Nice site. Please I want to work with you. Am Alhassan Haruna, from Ghana. Contact me +233553851616",
    time: 163319705,
  },
  {
    author_name: "Husuni Hamza",
    author_url:
      "https://www.google.com/maps/contrib/102167316656574288776/reviews",
    language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AATXAJwRkyvoSlgd06ahkF9XI9D39o6Zc_Oycm5EKuRg=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "7 months ago",
    text: "Nice site. Please I want to work with you. Am Alhassan Haruna, from Ghana. Contact me +233553851616",
    time: 163197305,
  },
  {
    author_name: "Husuni Hamza",
    author_url:
      "https://www.google.com/maps/contrib/102167316656574288776/reviews",
    language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AATXAJwRkyvoSlgd06ahkF9XI9D39o6Zc_Oycm5EKuRg=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "7 months ago",
    text: "Nice site. Please I want to work with you. Am Alhassan Haruna, from Ghana. Contact me +233553851616",
    time: 16197305,
  },
  {
    author_name: "Husuni Hamza",
    author_url:
      "https://www.google.com/maps/contrib/102167316656574288776/reviews",
    language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AATXAJwRkyvoSlgd06ahkF9XI9D39o6Zc_Oycm5EKuRg=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "7 months ago",
    text: "Nice site. Please I want to work with you. Am Alhassan Haruna, from Ghana. Contact me +233553851616",
    time: 16197305,
  },
  {
    author_name: "Husuni Hamza",
    author_url:
      "https://www.google.com/maps/contrib/102167316656574288776/reviews",
    language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AATXAJwRkyvoSlgd06ahkF9XI9D39o6Zc_Oycm5EKuRg=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "7 months ago",
    text: "Nice site. Please I want to work with you. Am Alhassan Haruna, from Ghana. Contact me +233553851616",
    time: 16197305,
  },
  {
    author_name: "Husuni Hamza",
    author_url:
      "https://www.google.com/maps/contrib/102167316656574288776/reviews",
    language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AATXAJwRkyvoSlgd06ahkF9XI9D39o6Zc_Oycm5EKuRg=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "7 months ago",
    text: "Nice site. Please I want to work with you. Am Alhassan Haruna, from Ghana. Contact me +233553851616",
    time: 16197305,
  },
  {
    author_name: "Husuni Hamza",
    author_url:
      "https://www.google.com/maps/contrib/102167316656574288776/reviews",
    language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AATXAJwRkyvoSlgd06ahkF9XI9D39o6Zc_Oycm5EKuRg=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "7 months ago",
    text: "Nice site. Please I want to work with you. Am Alhassan Haruna, from Ghana. Contact me +233553851616",
    time: 16197305,
  },
  {
    author_name: "Husuni Hamza",
    author_url:
      "https://www.google.com/maps/contrib/102167316656574288776/reviews",
    language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AATXAJwRkyvoSlgd06ahkF9XI9D39o6Zc_Oycm5EKuRg=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "7 months ago",
    text: "Nice site. Please I want to work with you. Am Alhassan Haruna, from Ghana. Contact me +233553851616",
    time: 16197305,
  },
];

export const useReviews = () => {
  const result = reviews.map((x) => ({
    name: x.author_name,
    photo: x.profile_photo_url,
    rating: x.rating,
    time: x.relative_time_description,
    text: x.text,
  }));
  const half = Math.ceil(result.length / 2);

  const firstHalf = result.slice(0, half);
  const secondHalf = result.slice(half);
  return [firstHalf, secondHalf];
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {};

import { useRef } from "react";
import { useScroll, useTransform } from "framer-motion";

import SectionTitle from "../../components/Text/SectionTitle";
import AboutUsCard from "./AboutUsCard";
import LanguageTicker from "./LanguageTicker";
import aboutUsService from "../../assets/circle-about-us.svg";
import toothIcon from "../../assets/tooth-icon.svg";
import moss from "../../assets/hero-moss-background.jpeg";

const AboutUs = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 1800]);
  return (
    <div
      id="services"
      ref={targetRef}
      style={{ "--image-url": `url(${moss})` }}
      className="relative flex flex-col pt-20 z-10 w-[100vw] pb-40 bg-cover bg-[image:var(--image-url)]"
    >
      <div className=" absolute w-full h-full left-0 right-0 top-0 bottom-0  bg-gradient-to-b from-black/80 to-black/40"></div>
      <SectionTitle
        classes="text-white z-10"
        icon={aboutUsService}
        rotate={rotate}
      >
        About Us
      </SectionTitle>
      <div className="w-5/6 m-auto grid grid-cols-3 gap-4 z-10">
        <div className="bg-black/50 rounded-[10px]">
          <AboutUsCard delay="0.05">
            <div className="font-clashdisplay text-white text-[16px] font-[400] pb-16">
              Experience
            </div>
            <div className="font-poppinsRegular text-white text-[16px] leading-normal">
              <b>Do you experience</b> an unspoken bout of anxiety in your mind
              at the thought of going to the dentist? You are sure not alone! We
              completely understand that this stressful anticipation associated
              with the dental office has gone on for far too long.
            </div>
          </AboutUsCard>
        </div>
        <div className="bg-black/50 rounded-[10px]">
          <AboutUsCard delay="0.1">
            <div className="font-clashdisplay text-white text-[16px] font-[400] pb-16">
              Place
            </div>
            <div className="font-poppinsRegular text-white text-[16px] leading-normal">
              <b>Dentio Dental</b> is the place where those uneasy concerns stop
              holding you back. Our practice helps you see the opportunistic
              dental chair for what it truly is – a chance to optimize the
              health of your teeth and obtain a showstopping smile. Those pearly
              whites won’t clean themselves!
            </div>
          </AboutUsCard>
        </div>
        <div className="row-span-2 bg-black/50 rounded-[10px]">
          <AboutUsCard delay="0.15" classes="bg-dark-green/50">
            <div className="font-clashdisplay text-white text-[16px] font-[400] pb-16">
              Languages
            </div>
            <div className="font-poppinsRegular text-white text-[16px] leading-normal">
              In this space, we speak{" "}
              <b>English, Mandarin, Indonesian, Tagalog and Taiwanese</b>. With
              this level of diversity in language, our renowned clinicians
              support a relaxing environment for all who wish to enjoy its
              essence!
            </div>
            <LanguageTicker />
          </AboutUsCard>
        </div>
        <div className="col-span-2 bg-black/50 rounded-[10px]">
          <AboutUsCard delay="0.2">
            <div className="h-[100%] flex items-center justify-between gap-10">
              <img className="w-[32px] h-[32px]" alt="teeth" src={toothIcon} />
              <div className="flex-1 font-poppinsRegular text-white text-[16px] leading-normal">
                Immerse yourself into a modern atmosphere that delivers the
                serene dental visit of your dreams! Let us guide you through
                this eye-opening dental journey.
              </div>
            </div>
          </AboutUsCard>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

import { motion } from "framer-motion";
import PageTransition from "../components/PageTransition";

import Hero from "./Hero";
import ContactUs from "./ContactUs";

function About() {
  return (
    <motion.div className="bg-black relative">
      <PageTransition />
      <Hero />
      <ContactUs />
    </motion.div>
  );
}

export default About;

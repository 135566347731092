import { motion } from "framer-motion";
import classNames from "classnames";

const Button = ({ padding, text, label, classes, disableHover }) => {
  const border = "absolute block bg-black transition-all";
  return (
    <motion.button
      whileHover={disableHover ? {} : { scale: 1.05 }}
      transition={{ duration: 0.125, type: "spring", stiffness: 200 }}
      className={classNames(
        `${classes} transition relative font-clashdisplay cursor-pointer rounded-full font-thin`,
        padding ? padding : "px-5 py-3"
      )}
    >
      <span className={text}>{label}</span>
      <div
        className={classNames(
          border,
          "left-0 top-0 w-[50.5%] h-[1px] scale-x-0 hover:scale-x-100"
        )}
      />
    </motion.button>
  );
};

export default Button;

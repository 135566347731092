import { motion } from "framer-motion";

import MarqueeText from "react-marquee-text-component";
import { tw } from "../../utils";

const LanguageTicker = () => {
  return (
    <motion.div className="cursor-grab overflow-hidden w-[100%] h-[100%] p-2 mt-6">
      <div
        className={tw(
          "flex flex-col self-center",
          "gap-2 h-400",
          "md:flex md:flex-row md:justify-between md:gap-8"
        )}
      >
        <MarqueeText
          className="px-[20px] text-center text-[135px] text-light-green font-[700]"
          repeat="3"
          duration="10"
          text="中文 • EN • 言語 • 한국어 •"
        />
      </div>
    </motion.div>
  );
};

export default LanguageTicker;

import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import Hero from "./Hero";
import Services from "./Services";
import AboutUs from "./AboutUs";
import OfficeSlider from "./OfficeSlider";
import OurTeam from "./OurTeam";
import PageTransition from "../components/PageTransition";
import Testimonials from "./Testimonials";
import ContactUs from "./ContactUs";
import GetInTouch from "./GetInTouch";
import Socials from "./SocialMedia";
// import FlipCard from "../components/FlipCard";
import Slider from "./Slider";
import OurServices from "./OurServices";
import { tw } from "../utils";

function Landing() {
  return (
    <motion.div className="app bg-white relative">
      <PageTransition />
      <Hero />
      <AboutUs />
      <OurTeam />
      <Testimonials />
      <OfficeSlider />
      {/* <Services /> */}
      <OurServices />
      {/* <Slider /> */}
      <ContactUs />
      <GetInTouch />
      <Socials />
      {/* <FlipCard /> */}
    </motion.div>
  );
}

export default Landing;

import exam1 from "../../assets/exam-1.jpeg";
import exam2 from "../../assets/exam-2.jpeg";
import cleaning1 from "../../assets/cleaning-1.jpeg";
import cleaning2 from "../../assets/cleaning-2.jpeg";
import cosmetics1 from "../../assets/cosmetics-1.jpeg";
import cosmetics2 from "../../assets/cosmetics-2.jpeg";
import cosmetics3 from "../../assets/cosmetics-3.png";
import restorative1 from "../../assets/restorative-1.png";

export const list = [
  {
    darkTheme: false,
    page: "Exam checkup",
    description:
      "Let's check your teeth to make sure everything is in top shape.",
    services: [
      {
        title: "New patient Exam",
        image: exam1,
        descriptionItalic:
          "We want to understand your story and your teeth will tell us all we need to know!",
        description:
          "For all new patients, we conduct a comprehensive and personalized exam to record and identify any dental-related or potential systemic issues. X-rays as well as appropriate intra-oral photos will be taken at this appointment to properly make diagnoses based on the facts!",
        book: "",
        call: "",
        duration: [
          "Approx. 30 - 45 min without hygiene",
          "Approx. 90 min with hygiene",
        ],
      },
      {
        title: "Emergency/single tooth exam",
        image: exam2,
        descriptionItalic: "Ouch! Something’s not right…",
        description:
          "Toothache? Swelling? Broken tooth? Loose/cracked old fillings? No matter the problem, we'll see you as soon as possible to alleviate the pain. Our emergency services require a more localized exam to address a more specific problem. The X-rays will help us help you!",
        book: "",
        call: "",
        duration: ["Approx. 30 min"],
      },
    ],
  },
  {
    darkTheme: true,
    page: "Cleaning",
    description:
      "Get squeaky clean teeth for a special event, date night, or just because.",
    services: [
      {
        title: "New patient Exam",
        image: cleaning1,
        titleButton: "Existing patient",
        descriptionItalic: "Maintenance, maintenance, maintenance!",
        description:
          "The real difference between pearly whites that shine and sore gums that ache is consistent care. If you think about it, regular hygiene is exactly like servicing your car! Let us help you locate and clean out those stubborn plaques to restore your optimal oral health.",
        book: "",
        call: "",
        duration: ["Approx. 60 min"],
      },
      {
        title: "Quick touch-up!",
        image: cleaning2,
        titleButton: "basic oral hygiene and teeth polishing",
        description:
          "We get it! Sometimes life happens and unplanned events make it onto the schedule last minute! Got an important date or meeting coming up? Not a problem! We'll make sure you have all eyes on you when you walk in the room with that fabulous smile on full display.",
        book: "",
        call: "",
        duration: ["Approx. 15-30 min"],
      },
    ],
  },
  {
    darkTheme: false,
    page: "Cosmetic",
    description: "Look into options for creating your desired smile.",
    services: [
      {
        title: "Veneers consultation",
        image: cosmetics1,
        descriptionItalic:
          'Do you long for that glamorous "Hollywood" smile that makes your self-confidence soar?',
        description:
          "If you answered yes, then veneers are the way to go! Offering immediate transformation with long-lasting results, this ultra-conservative dental procedure is perfect for you. We only remove the necessary parts of your teeth to ensure you leave with the smile you've always adored.",
        book: "",
        call: "",
        duration: ["Approx. 30 min"],
      },
      {
        title: "Ortho consultation",
        image: cosmetics2,
        description: (
          <span>
            <span>
              Do your teeth look crooked or feel packed into your mouth? Does
              your bite cause damage to your teeth due to attrition? If
              traditional braces (with metal brackets and wires) are not an
              option for you, we might have the solution!
            </span>
            <br />
            <span>
              Come in for an initial consultation to see if clear aligners are
              exactly what you need.
            </span>
          </span>
        ),
        book: "",
        call: "",
        duration: ["Approx. 30 min"],
      },
      {
        title: "Whitening",
        image: cosmetics3,
        description: (
          <span>
            <span>
              Are your teeth prone to getting stained from regular coffee, tea,
              or food consumption? Do you ever shy away from smiling at the
              camera because you’re embarrassed about the color of your teeth?
            </span>
            <br />
            <span>
              Fortunately, with in-office whitening, we can brighten your teeth
              in only 60 minutes. We are here to help you regain confidence in
              your showstopping smile!
            </span>
          </span>
        ),
        book: "",
        call: "",
        duration: ["Approx. 60 min"],
      },
    ],
  },
  {
    darkTheme: true,
    page: "Restorative",
    description:
      "Remedy those cavities, chips or cracks in just a quick visit.",
    services: [
      {
        title: "Other procedures",
        image: restorative1,
        descriptionItalic: "Do any of the services above catch your eye?",
        descriptionButton: [
          "Composite Fillings",
          "Crowns",
          "Bridges",
          "Root Canal Treatment",
          "Extraction (including Wisdoms)",
          "Implants",
        ],
        description: (
          <span>
            <span>
              If you need any of these services, please schedule your "New
              Patient Exam" with us first! This will allow us to create a more
              comprehensive and personalized treatment plan for you. Within this
              plan, we will address and prioritize every potential dental issue
              you may have.
            </span>
            <br />
            <span>
              Let our talented clinicians take care of your teeth and transform
              your smile!
            </span>
          </span>
        ),
        book: "",
        call: "",
        duration: ["Approx. 30 min"],
      },
    ],
  },
];

export default {};

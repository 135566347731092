import { motion } from "framer-motion";

const AboutUsCard = ({ classes, delay, children }) => {
  return (
    <motion.div
      className={`w-full h-full py-14 px-10 md:text-left border-[0.5px] border-light-green/[0.2] border-solid rounded-[10px] ${classes}`}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      transition={{ duration: 0.5, delay }}
      variants={{
        hidden: { scale: 0, opacity: 0 },
        visible: { scale: 1, opacity: 1 },
      }}
    >
      {children}
    </motion.div>
  );
};

export default AboutUsCard;

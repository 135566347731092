import { motion } from "framer-motion";
import classNames from "classnames";
import "./HoverButton.css";

const Button = ({ label, classes }) => {
  const handleMouseMove = (e) => {
    const hover = document.getElementsByClassName("hover-button")[0];
    const target = e.target.getBoundingClientRect();
    const x = e.clientX - target.left;
    hover.style.setProperty("--x", x + "deg");
  };
  const handleMouseEnter = (e) => {
    console.log("adding");
    const button = document.getElementsByClassName("ripple-container")[0];
    const target = e.target.getBoundingClientRect();
    const x = e.clientX - target.left;
    const y = e.clientY - target.top;
    const ripple = document.createElement("div");
    ripple.classList.add("ripple");
    ripple.style.left = x + "px";
    ripple.style.top = y + "px";
    button.appendChild(ripple);
    setTimeout(() => {
      ripple.remove();
    }, 1000);
  };
  return (
    <button
      className="hover-button"
      onMouseMove={handleMouseMove}
      onClick={handleMouseEnter}
    >
      <span>Book now</span>
      <i />
      <i />
      <div className="ripple-container" />
    </button>
  );
};

export default Button;

import { useRef } from "react";
import { useScroll, useTransform } from "framer-motion";

import SectionTitle from "../../components/Text/SectionTitle";

import circle from "../../assets/circle-socials.svg";

const SocialMedia = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 1800]);
  return (
    <div
      id="services"
      ref={targetRef}
      className="relative flex flex-col z-10 w-[100vw] bg-white pb-40"
    >
      <SectionTitle icon={circle} rotate={rotate} text="text-dark-green">
        Our socials
      </SectionTitle>
      <div className="flex gap-10 w-5/6 h-[500px] m-auto justify-between border-[1px] rounded-3xl">
        <div></div>
      </div>
    </div>
  );
};

export default SocialMedia;

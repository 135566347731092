import { motion, AnimatePresence } from "framer-motion";

function PageTransition() {
  return (
    <>
      {/* <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="dentio-page-transition h-full w-full m-auto font-clashdisplay text-white text-[200px] z-[100]"
      >
        Dentio
      </motion.div> */}
      <motion.div
        className="absolute top-0 left-0 w-full h-full bg-dark-green origin-bottom z-50"
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 1 }}
        transition={{ duration: 0.5 }}
      />
      <motion.div
        className="absolute top-0 left-0 w-full h-full bg-dark-green origin-top z-50"
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 0 }}
        transition={{ duration: 0.5 }}
      />
    </>
  );
}

export default PageTransition;

import { motion, AnimatePresence } from "framer-motion";
import { FiX as Cancel } from "react-icons/fi";
import useMediaQuery from "../../hooks/useMediaQuery";

import { tw } from "../../utils";

const backdrop = {
  hidden: { opacity: 0 },
  exit: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
  },
};

const Promotion = ({ open, onClose }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const modal = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visibleMobile: {
      y: 50,
      opacity: 1,
      transition: {
        delay: 0.5,
        type: "spring",
        duration: 0.5,
      },
    },
    visible: {
      y: 200,
      opacity: 1,
      transition: {
        delay: 0.5,
        type: "spring",
        duration: 0.5,
      },
    },
    exit: {
      y: "-100vh",
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.5,
      },
    },
  };

  return (
    <AnimatePresence mode="wait">
      {open && (
        <motion.div
          className="backdrop"
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <motion.div
            className={tw(
              "flex w-5/6 h-5/6",
              "md:h-[400px] md:w-[700px]",
              "bg-green rounded-xl m-auto p-8 opacity-80"
            )}
            variants={modal}
            animate={isMobile ? "visibleMobile" : "visible"}
            initial="hidden"
            exit="exit"
          >
            <div className="flex-1 text-center mx-auto">Promotion Page</div>
            <div className="self-start">
              <button onClick={onClose}>
                <Cancel className="text-white cursor-pointer" size={32} />
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Promotion;

import { useRef } from "react";

import BorderButton from "../../components/Button/BorderButton";
import { motion, useScroll, useTransform } from "framer-motion";
import icon from "../../assets/circle-more.svg";
import { list } from "./serviceList";
import ServiceCard from "./ServiceCard";
import { v4 as uuidv4 } from "uuid";
const h1Variant = {
  hidden: { opacity: 0, y: -250 },
  visible: { opacity: 1, y: 0 },
};
const h2Variant = {
  hidden: { opacity: 0, y: -100 },
  visible: { opacity: 1, y: 0 },
};
const h3Variant = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};
const Hero = ({ setSelectedPage }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 1800]);
  return (
    <motion.div
      ref={targetRef}
      id="home"
      className="pt-[90px] flex flex-col relative bg-black pb-6"
    >
      <motion.div
        variants={h1Variant}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        whileHover={{
          scale: 1.01,
          transition: { duration: 1 },
        }}
        transition={{ duration: 1, delay: 0.2, type: "spring" }}
        className="w-5/6 mt-[100px] leading-[74px] mx-auto font-clashdisplay text-white text-[94px]"
      >
        Our services
      </motion.div>{" "}
      <div className="h-[1px] bg-white opacity-20 mt-3" />
      <motion.div
        variants={h2Variant}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 0.4, type: "spring" }}
        className="flex w-5/6 mx-auto gap-5 mt-8"
      >
        <BorderButton label="Exam checkup" />
        <BorderButton label="Cleaning" />
        <BorderButton label="Restorative" />
        <BorderButton label="Cosmetic" />
      </motion.div>
      <motion.div
        variants={h3Variant}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 0.6, type: "spring" }}
        className="flex w-5/6 mx-auto mt-20 pb-16"
      >
        <motion.img
          style={{ rotate }}
          className="w-[65px] h-[65] cursor-grab"
          alt={"more"}
          src={icon}
          drag
          dragElastic={0.18}
          dragConstraints={{ top: 0, bottom: 0, right: 0, left: 0 }}
          whileTap={{ cursor: "grabbing" }}
        />
      </motion.div>
      {list.map((x) => (
        <ServiceCard key={uuidv4()} service={x} />
      ))}
    </motion.div>
  );
};

export default Hero;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import LoadingScreen from "./components/LoadingScreen";
import Promotion from "./components/PromotionDialog";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AnimatedRoutes from "./routes";

function App() {
  // const [loading, setLoading] = useState(true);
  const [showPromotion, setShowPromotion] = useState(false);
  const [session] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      const promotion = session
        ? window.sessionStorage.getItem("promotion-closed")
        : false;
      setShowPromotion(!promotion);
    }, 1000);
  }, [session]);

  const onClose = () => {
    if (session) {
      window.sessionStorage.setItem("promotion-closed", true);
    }

    setShowPromotion(false);
  };
  // if (loading) {
  //   return <LoadingScreen onEnded={() => setLoading(false)} />;
  // }

  return (
    <>
      <Promotion open={showPromotion} onClose={onClose} />
      <Router>
        <Navbar />
        <AnimatedRoutes />
        <Footer />
      </Router>
    </>
  );
}

export default App;

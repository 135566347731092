import { useRef } from "react";

import { motion, useScroll, useTransform } from "framer-motion";
import phone from "../../assets/phone.svg";
import email from "../../assets/email.svg";
import address from "../../assets/address.svg";

const h1Variant = {
  hidden: { opacity: 0, y: -250 },
  visible: { opacity: 1, y: 0 },
};
const h2Variant = {
  hidden: { opacity: 0, y: -100 },
  visible: { opacity: 1, y: 0 },
};
const h3Variant = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};
const Hero = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 1800]);
  return (
    <motion.div
      ref={targetRef}
      id="home"
      className="h-[100vh] pt-[90px] flex flex-col relative bg-black pb-6"
    >
      <div className="flex w-full h-full">
        <div className="min-w-[50%] h-full flex flex-col p-[100px] text-left gap-8">
          <div className="font-clashdisplay text-[94px] w-fit">
            About us
            <div className="border-b-[1px] solid border-light-green -mt-[16px]" />
          </div>
          <div className="flex mt-10">
            <div className="flex items-center justify-center rounded-full bg-light-green w-[70px] h-[70px]">
              <img className="" alt="phone" src={phone} />
            </div>
            <div className="flex flex-col gap-2 ml-6 justify-center">
              <span className="font-clashdisplay text-[16px] text-light-green">
                Phone
              </span>
              <span className="font-clashdisplay text-[18px] text-white">
                604-227-2232
              </span>
            </div>
          </div>
          <div className="flex">
            <div className="flex items-center justify-center rounded-full bg-light-green w-[70px] h-[70px]">
              <img className="" alt="email" src={email} />
            </div>
            <div className="flex flex-col gap-2 ml-6 justify-center">
              <span className="font-clashdisplay text-[16px] text-light-green">
                Email
              </span>
              <span className="font-clashdisplay text-[18px] text-white">
                hello@dentio.ca
              </span>
            </div>
          </div>
          <div className="flex">
            <div className="flex items-center justify-center rounded-full bg-light-green w-[70px] h-[70px]">
              <img className="" alt="address" src={address} />
            </div>
            <div className="flex flex-col gap-2 ml-6 justify-center">
              <span className="font-clashdisplay text-[16px] text-light-green">
                Address
              </span>
              <span className="font-clashdisplay text-[18px] text-white">
                Unit E2, 10118 - 152th Street, Surrey, BC, V3R 9W3
              </span>
            </div>
          </div>
        </div>
        <div className="min-w-[50%] h-full bg-white"></div>
      </div>
    </motion.div>
  );
};

export default Hero;

import { useRef } from "react";
import classNames from "classnames";
import { useScroll, useTransform } from "framer-motion";
import TextField from "../../components/TextField";
import MultilineTextField from "../../components/TextField/MultilineTextField";
import { motion } from "framer-motion";

import SectionTitle from "../../components/Text/SectionTitle";
import BorderButton from "../../components/Button/BorderButton";

import circleOurTeam from "../../assets/circle-contact-us.svg";
import chairIcon from "../../assets/chair-icon.svg";
import background from "../../assets/contact-us.png";

const contentVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};
const OurTeam = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 1800]);
  return (
    <div
      id="services"
      ref={targetRef}
      className="relative flex flex-col pt-20 z-10 w-[100vw] bg-white pb-40"
    >
      <SectionTitle icon={circleOurTeam} rotate={rotate}>
        Contact Us
      </SectionTitle>
      <motion.div
        variants={contentVariant}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1, delay: 0.5, type: "spring" }}
        className="max-w-4/6 px-32 flex"
      >
        <div className="grid grid-cols-2 gap-4 w-[65%]">
          <div className="col-span-2 text-[18px] text-black font-poppinsLight leading-8 py-5 pr-10">
            We would like to hear from you! Share your thoughts, questions and
            comments here and we will be sure to reach back to you as soon as
            possible.
          </div>
          <div className="">
            <TextField label="First Name" placeholder="Enter here" />
          </div>
          <div className="">
            <TextField label="Last Name" placeholder="Enter here" />
          </div>
          <div className="">
            <TextField label="Email" placeholder="Enter here" />
          </div>
          <div className="">
            <TextField label="Phone" placeholder="Enter here" />
          </div>
          <div className="col-span-2">
            <MultilineTextField label="Message" placeholder="Write a message" />
          </div>
          <BorderButton
            classes="w-[180px] text-[20px] border-black bg-black "
            label="Book now"
          />
        </div>
        <div className="flex justify-center items-end w-[35%] mb-6">
          <div
            style={{
              "--image-url": `url(${background})`,
            }}
            className="relative w-[270px] h-[427px]  rounded-full bg-[image:var(--image-url)] bg-black"
          >
            <div className="absolute right-0 bottom-0 h-[80px] w-[80px] flex justify-center items-center bg-dark-green rounded-full">
              <img alt="contact icon" src={chairIcon} />
            </div>
            <div
              className={classNames(
                "absolute m-auto inset-0 border-dark-green/20 h-[calc(100%-20px)] w-[calc(100%-20px)] rounded-[153px] border-solid border-[1px]"
              )}
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default OurTeam;

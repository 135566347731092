import { motion } from "framer-motion";
import classNames from "classnames";
import "./SquareButton.css";

const Button = ({ label, classes }) => {
  return (
    <button className="square-button">
      Book now
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </button>
  );
};

export default Button;

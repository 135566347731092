import React, { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import { tw } from "../../utils";
import "./OfficeSlider.css";

import arrowRight from "../../assets/arrow-right.svg";
import arrowLeft from "../../assets/arrow-left.svg";

import slide1 from "../../assets/office-1.jpg";
import slide2 from "../../assets/office-2.jpg";
import slide3 from "../../assets/office-3.jpg";
import slide4 from "../../assets/office-4.jpg";
import slide5 from "../../assets/office-5.jpg";
import slide6 from "../../assets/office-6.jpg";
import slide7 from "../../assets/office-7.jpg";
import slide8 from "../../assets/office-8.jpg";
import slide9 from "../../assets/office-9.jpg";

const slides = [
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  slide6,
  slide7,
  slide8,
  slide9,
];

const variants = {
  initial: (direction) => {
    return {
      opacity: 0,
    };
  },
  animate: {
    opacity: 1,
    // transition: 'ease-in',
    transition: {
      opacity: { ease: "linear", duration: 1 },
    },
  },
  exit: (direction) => {
    return {
      opacity: 0,
      // transition: 'ease-in',
      transition: {
        opacity: { ease: "linear", duration: 1 },
      },
    };
  },
};

function Image({ index, direction, url }) {
  const ref = useRef(null);
  return (
    <AnimatePresence initial={false} custom={direction}>
      <motion.div
        className={`office-container left-[-50vw]`}
        variants={variants}
        animate="animate"
        initial="initial"
        exit="exit"
        src={url}
        alt="slides"
        key={index}
        custom={direction}
      >
        <div
          ref={ref}
          style={{ "--image-url": `url(${url})` }}
          className="office-image bg-cover bg-center bg-[image:var(--image-url)]"
        ></div>
      </motion.div>
    </AnimatePresence>
  );
}

const buttons = {
  whileHover: {
    scale: 1.05,
    transition: { duration: 0.4 },
  },
  whileTap: { scale: 0.95 },
};

function OfficeSlider() {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  function onRight() {
    setDirection(1);
    if (index === slides.length - 1) {
      setIndex(0);
      return;
    }
    setIndex(index + 1);
  }

  function onLeft() {
    setDirection(-1);
    if (index === 0) {
      setIndex(slides.length - 1);
      return;
    }
    setIndex(index - 1);
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => onRight(),
    onSwipedRight: () => onLeft(),
    swipeDuration: 1000,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });
  return (
    <div className="relative flex items-center z-10 w-[100vw]">
      <div className="office-app">
        <div className="office-row" {...handlers}>
          <Image index={index} url={slides[index]} direction={direction} />
        </div>
      </div>

      <motion.div
        whileHover="whileHover"
        whileTap="whileTab"
        variants={buttons}
        onClick={onLeft}
        className={tw(
          "flex items-center justify-center",
          "absolute my-auto left-[2vw]",
          "w-[200px] h-[80px] bg-black",
          "rounded-[70px] opacity-0 cursor-pointer sm:opacity-90"
        )}
      >
        <img className="m-auto" alt="left" src={arrowLeft} />
      </motion.div>
      <motion.div
        whileHover="whileHover"
        whileTap="whileTab"
        variants={buttons}
        onClick={onRight}
        className="flex items-center justify-center w-[200px] h-[80px] rounded-[70px] opacity-0 bg-black absolute my-auto right-[2vw] cursor-pointer sm:opacity-90"
      >
        <img className="m-auto" alt="right" src={arrowRight} />
      </motion.div>
    </div>
  );
}

export default OfficeSlider;

const MultilineTextField = ({ label, placeholder }) => {
  return (
    <div className="flex flex-col w-full h-full gap-2">
      <div className="font-clashdisplay text-[14px] text-light-green">
        {label}
      </div>
      <textarea
        className="h-[150px] rounded-2xl py-6 px-5 border-solid border-light-green border-[1px] text-black hover:border-[1px] hover:border-dark-green outline-light-green resize-none placeholder:text-black/10 bg-white"
        type="text"
        placeholder={placeholder}
        onChange={() => {}}
      />
    </div>
  );
};

export default MultilineTextField;

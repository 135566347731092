import classNames from "classnames";
import Button from "./Button";

const BorderButton = ({ padding, bc, text, label, disableHover, classes }) => {
  return (
    <Button
      classes={classNames(
        `${classes} border-solid border flex justify-center`,
        padding ? padding : "p-5"
      )}
      label={label}
      padding={padding}
      disableHover={disableHover}
      text={text}
    />
  );
};

export default BorderButton;

import { motion } from "framer-motion";

const SectionTitle = ({ icon, classes, text, rotate, children }) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      transition={{ duration: 0.5 }}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
      }}
      className="flex flex-col pb-10"
    >
      <div
        className={`flex justify-between w-5/6 mx-auto font-clashdisplay font-normal text-[94px]  ${classes} ${
          text ?? "text-light-green"
        }`}
      >
        {children}
        <motion.img
          style={{ rotate }}
          className="w-[65px] h-[65] cursor-grab"
          alt={children}
          src={icon}
          drag
          dragElastic={0.18}
          dragConstraints={{ top: 0, bottom: 0, right: 0, left: 0 }}
          whileTap={{ cursor: "grabbing" }}
        />
      </div>
      <div className="border-b-[1px] solid border-light-green opacity-20" />
    </motion.div>
  );
};

export default SectionTitle;
